@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'DINNextLTPro-Regular';
	src: url('./fonts/DINNextLTPro-Bold.otf');
	font-weight: normal;
	font-style: normal;
}

@layer base {
	.ddn_container {
		background-image: url('./assets/header-background.png');
	}
	.map {
		background-image: url('./assets/map.png');
	}
	.news-container {
		background-image: url('./assets/trydnn-globe.png');
	}
}

.primary-color {
	color: #005aff;
}
.primary-color-text {
	color: #6a7889;
}
.primary-bg {
	background-color: #005aff;
}
.secondary-bg {
	background-color: #0080ff;
}

* {
	font-family: 'Inter';
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
